.card {
    justify-content: center;
    width: 100%;
    min-width: 280px;
}

.collectionWrapper {
    margin-bottom: 67px;
}

@media screen and (min-width: 1020px) and (max-width: 1199px) {
    .card {
        flex: 0 0 50%;
        min-width: 256px !important;
    }
}

@media screen and (min-width: 1200px) {
    .card {
        margin-left: 8px;
        margin-right: 8px;
        flex: 0 0 30%;
        min-width: 256px !important;
    }
}
