@media screen and (min-width: 992px) {
    .profileInfoContainer {
        max-width: 350px;
    }
}

.editButtonContainer {
    display: flex;
    margin: 48px 0px 64px 0px;
}

.editButton {
    width: 100%;
    height: 56px;

    border: 1px solid #383639;
    box-sizing: border-box;
    border-radius: 73px;

    background: transparent;
    color: #383639;
}

.subscriptionContainer {
    margin: 48px 0px 64px 0px;
}

.subscriptionButtonContainer {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}

.proirityPass {
    margin-right: 16px;
    width: 140%;
    height: 56px;

    border: 1px solid #e54fc4;
    box-sizing: border-box;
    border-radius: 73px;

    background: transparent;
    color: #e54fc4;
}
@media (hover: hover) {
    .proirityPass:hover {
        background: #e54fc4;
        color: #ffffff;
    }
}

.proirityPassSubscribed {
    height: 56px;

    border: 1px solid #e54fc4;
    box-sizing: border-box;
    border-radius: 73px;

    background: #e54fc4;
    color: #ffffff;
}

.subscribe {
    /*width: 120px;*/
     width: 100%;
    height: 56px;

    border: 1px solid #383639;
    box-sizing: border-box;
    border-radius: 73px;

    background: transparent;
    color: #383639;
}

@media (hover: hover) {
    .subscribe:hover {
        background: #383639;
        color: #ffffff;
    }
}

.subscribed {
    /*width: 120px;*/
     width: 100%;
    height: 56px;

    border: 1px solid #383639;
    box-sizing: border-box;
    border-radius: 73px;

    background: #383639;
    color: #ffffff;
}

@media screen and (max-width: 991px) {

    .editButtonContainer {
        margin: 24px 0px 48px 0px;
        justify-content: center;
    }

    .editButton {
        width: 300px;
        height: 48px;
    }

    .subscriptionContainer {
        margin: 24px 0px 48px 0px;
        justify-content: center;
        text-align: center;
    }
    .subscriptionButtonContainer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .proirityPass {
        /*width: 140px;*/
        width: 70%;
        height: 48px;
        margin-bottom: 15px;
        margin-right: 0;
    }
    .subscribe {
        /*width: 110px;*/
         width: 70%;
        height: 48px;
    }
    .subscribed {
        /*width: 110px;*/
         width: 70%;
        height: 48px;
    }
}

/* -- Priority Pass Modal Styles -- */

.proirityPassModal {
    max-width: 430px;
    padding: 8px;
}

.description {
    display: flex;
    flex-direction: column;

    margin: 20px 0px 32px 0px;
}

.description li::marker {
    color: #ffffff;
}

.buyButton {
    margin: 0px;
    padding: 18px 24px;

    min-height: 56px;

    background: #a12ceb;

    border: none;
    border-radius: 35px;

    color: #fefefe;
}
.unsubModalButton {
    margin: 0px;
    padding: 18px 24px;

    min-height: 56px;

    background: #a12ceb;

    border: none;
    border-radius: 35px;

    color: #fefefe;
}

.cancelButton {
    margin: 0px;
    margin-top: 20px;
    padding: 0px;

    border: none;

    background: transparent;
    color: #fefefe;
}

/* ---------------------------- */
