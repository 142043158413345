.confetti {
    display: flex;
    flex-direction: column;

    align-items: center;

    background: url('/assets/img/bg-confetti.png');
    background-repeat: no-repeat;
    background-size: contain;

    padding: 64px;
}

.description {
    margin-top: 72px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .description {
        margin-top: 32px;
    }
}

.caption {
    margin-top: 16px;
    text-align: center;
}

.action {
    margin-top: 32px;
    width: 153px;
    height: 56px;
    border: none;
    border-radius: 35px;
    color: #fefefe;
    background: none;
    text-transform: uppercase;
    border: 1px solid #fefefe;
}

.cta {
    margin-top: 32px;
    width: 153px;
    height: 56px;
    border: none;
    border-radius: 35px;
    color: #fefefe;
    background: #e54fc4;
    text-transform: capitalize;
}
