.stickyRow {
    backdrop-filter: blur(20px);
    background: rgba(254, 254, 254, 0.8);
}
.stickyContent {
    border-radius: 36px;
    width: fit-content;
}
.imageDiv {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    overflow: hidden;
}
