.celProfilePicture {
    margin-top: -90px !important;
    height: 207px !important;
    width: 207px !important;
    border: 0px !important;
    border-radius: 50% !important;
    margin-left: -5px !important;
}

.celPrflVerifyIcon {
    position: absolute;
    width: 66.16px;
    height: 69.17px;
    left: 74.33%;
    top: -133.67%;
}
@media screen and (max-width: 600px) {
    .celPrflVerifyIcon {
        position: absolute;
        width: 38.16px;
        height: 40.17px;
        left: 70.33%;
        top: -210.67%;
    }
}
@media screen and (max-width: 991px) {
    .celPrflVerifyIcon {
        position: absolute;
        width: 38.16px;
        height: 40.17px;
        left: 70.33%;
        top: -210.67%;
    }
}

.celPrflHeaderTabs {
    margin-top: -38px !important;
    height: 64px;
    background: #fefefe;
    border: 1px solid #f1eef1;
    border-radius: 40px;
    max-width: 816px;
}

.profileImageNameDiv {
    box-shadow: 0px 6px 24px 17px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    width: fit-content !important;
}

.profilePicture {
    margin-top: -120px !important;
    border: 0px;
    height: 207px;
    width: 207px;
    border-radius: 50%;
    object-fit: cover;
}

@media screen and (max-width: 991px) {
    .profilePicture {
        height: 120px;
        width: 120px;
        margin-top: -80px !important;
    }
}
