.theHeader {
    position: relative;
    height: 114px;
}

@media screen and (min-width: 600px) {
    .theHeader {
        height: 168px;
    }
}

/* .celPrflHeaderBackground {
    background: conic-gradient(
        from 155.86deg at 50% 50%,
        #df55ea 0deg,
        #e34dcc 71.25deg,
        #f9c17b 180deg,
        #68d0e0 275.62deg,
        #df55ea 360deg
    );
    position: absolute;
    height: 264px;
    width: 100%;
    object-fit: cover;
    z-index: -100;
    bottom: 0;
    left: 0;
    right: 0;
} */

.celPrflHeaderBackground {
    background: conic-gradient(
        from 155.86deg at 50% 50%,
        #df55ea 0deg,
        #e34dcc 71.25deg,
        #f9c17b 180deg,
        #68d0e0 275.62deg,
        #df55ea 360deg
    );
    position: absolute;
    height: 168px;
    width: 100%;
    object-fit: cover;
    z-index: -100;
    bottom: 0;
    left: 0;
    right: 0;
}

.celPrflHeader {
    position: absolute;
    height: 168px;
    width: 100%;
    object-fit: cover;
    z-index: -100;
    bottom: 0;
    left: 0;
    right: 0;
}

.uploadContainer {
    padding: 4px 8px;
    background: white;
    border-radius: 10px;
    border: 1px solid white;
    color: white;
    cursor: pointer;
    font-weight: normal;
    font-size: 18px;
}

.uploadContainer input[type='file'] {
    display: none;
    width: 100%;
    height: 100%;
}

.deleteContainer {
    padding: 4px 8px;
    background: white;
    border-radius: 10px;
    border: 1px solid white;
    color: white;
    cursor: pointer;
    font-weight: normal;
    font-size: 18px;
}
